.__add-private {
  display: flex;
  margin-bottom: 0.5em;
  justify-content: flex-end;
}

.ui.form.searchForm .fields {
  margin: 0;
}

.ui.form.searchForm .fields .field {
  flex-grow: 1;
}

.searchForm .buttons {
  display: flex;
  align-items: flex-end;
}

.searchForm .buttons .button {
  height: 38px;
}
