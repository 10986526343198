.__focused-file {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.__focused-file .__focused-image {
  max-width: 80%;
  max-height: 80%;
  align-self: center;
}
