.Tag-Card .__cover-container {
    position: relative;
    width: 100%; /* for IE 6 */
    margin-top: 15px;
    margin-bottom: 15px
}

.Tag-Card .__cover-container .__text-placer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    padding-left: 15px;
    color: #FFFFFF;
    padding-right: 15px;
}
.Tag-Card .__cover-container .__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 5px;
}

.Tag-Card .__cover-container .__body {
    margin-top: 5px;
}
