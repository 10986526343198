.EventForm-Description-TabsDescriptionsByLang {
  margin-bottom: 16px;
}
.EventForm-Description-TabsDescriptionsByLang .__tabTitle {
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
}

.EventForm-Description-TabsDescriptionsByLang .__tabTitle + .icon.close {
  cursor: pointer;
}
