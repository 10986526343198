.InputExpandingOnFocus {
  display: inline-block;
}

.InputExpandingOnFocus > .InputExpandingOnFocus-label {
  display: inline-block;
  white-space: nowrap;
}
.InputExpandingOnFocus > .InputExpandingOnFocus-input {
  transition: width 0.3s ease-in-out;
}
