.vz-campaigns-add-form .section-title {
  color: #8E8F93;
  border-bottom: solid 1px #8E8F93;
}

.vz-campaigns-add-form section:not(:first-child) .section-title {
  margin-top: 70px;
}

.vz-campaigns-add-small-subtitle,
.vz-campaigns-add-form label {
  margin-left: 8px;
  height: 17px;
  width: 100%;
  color: #5E6065;
  font-family: Lato;
  font-size: 14px;
  font-style: italic;
  line-height: 17px;
}