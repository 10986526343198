.vz-gallery .ui.card:first-child {
  margin-top: 1em;
}

.vz-gallery .ui.card:last-child {
  margin-bottom: 1em;
}

.vz-gallery .ui.card {
  margin: 1em;
  width: calc(20% - 2em);
}

.vz-gallery .ui.card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vz-file-card .menu {
  align-self: flex-start;
  margin-bottom: 1em;
}

.ui.card.vz-file-card > .content > .header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

.vz-file-card .__file-thumbnail {
  max-height: 300px;
  max-width: 100%;
  align-self: center;
  border: 1px solid #ffffff;
  box-shadow: 1px 1px 5px 1px #b9b9b9;
  border-radius: 3px;
  cursor: pointer;
}

.vz-file-card .list {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.vz-file-card .ui.dropdown {
  border: 1px solid #dededf;
  border-left: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border-radius: 0 3px 3px 0;
}

.vz-file-card .ui.dropdown > .text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
