.CalendarDayCell {
  height: 100%;
  max-width: 160px;
  position: relative;
  padding: 2px;
  width: 100%;
}

.CalendarDayCell .availibilityModalIcon {
  position: absolute;
  right: 2px;
  top: 2px;
}

.CalendarEventCard {
  color: white;
  font-size: 12px;
  margin: 2px 0px;
  padding: 5px;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.CalendarEventCard .collapsable-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CalendarEventsListPopup {
  max-height: 400px;
  overflow-y: scroll;
}
