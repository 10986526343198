.__bookingList {
  overflow-x: auto;
}

.__bookingList .ui.table td {
  padding: 0.4em;
}

.__bookingList .ui.table th {
  padding: 1em 0.4em 1em 0.4em;
}

.__followupForm {
  float: right;
  margin-right: 0.5em;
}
