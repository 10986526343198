.event-id-menu .menu-container {
  width: 15rem;
  padding: 5px 15px;
}

.event-id-menu .menu-button.view-event {
  margin-bottom: 15px;
}

.event-id-menu .menu-container .event-info {
  margin-top: 15px;
  text-transform: capitalize;
}

.event-id-menu .ui.segment {
  padding: 0;
  width: 15rem;
}
