.common-error {
  position: absolute;
  min-width: 75%;
  min-height: 50%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: monospace;
  white-space: pre;
  line-height: 1.;
}
