.__categoriesDropdown {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 300px !important;
}

.__categoriesDropdown .__categoryDropdownLabel .icon, 
.__categoriesDropdown .__categoryDropdownLabel .__tag, 
.__categoriesDropdown .__categoryDropdownLabel .__detail{
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.__categoriesDropdown .__categoryDropdownLabel .icon:first-of-type {
  width: 30px;
}