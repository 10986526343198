td.ew {
  background-image: url('../../assets/img/ew-logo-bg.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position-x: 100%;
  background-position-y: 0%;
}

.darker-row {
  background: #d3d3d3 !important; /* override default table line color */
}
