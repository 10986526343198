
/* Pictures section */
.vz-event-id-form .event-gallery-image {
  position: relative;
}

.vz-event-id-form .__image {
  width: 100%;
}

.vz-event-id-form .column {
  margin-bottom: 14px !important;
}

.vz-event-id-markdown-renderer {
  margin-bottom: 20px;
}

.section-media .ui.dropdown {
  border: 1px solid #dededf;
  border-left: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border-radius: 0 3px 3px 0;
}

.section-media .ui.dropdown > .text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.section-media .selectedImage {
  border: solid 7.5px #2C7355 !important;
  opacity: 0.85;
}

.__card {
  margin: 1em;
}