.DownloadPhotosModal .__ModalHeader {
  display: flex;
  flex-direction: column;
}

.DownloadPhotosModal .ui.cards > .__ImageCardError {
  background: rgba(255, 0, 0, .7);
}

.DownloadPhotosModal .__ImageCardError img {
  opacity: .7
}

.DownloadPhotosModal .ui.__ImageCardErrorMessage {
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  padding: 4px;
}

.DownloadPhotosModal .__ModalHeader h1 {
  font-size: inherit;
  margin: 0;
  text-align: center;
}

.DownloadPhotosModal .__ModalHeader button {
  padding: 0;
  border: 0;
  background: transparent;
  color: #4183c4;
  font-size: 1.2rem;
  cursor: pointer;
}

.DownloadPhotosModal .__ModalHeader button:hover,
.DownloadPhotosModal .__ModalHeader button:focus {
  text-decoration: underline;
}

.DownloadPhotosModal .__ResizeForm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px
}

.DownloadPhotosModal .__ResizeOptionItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.DownloadPhotosModal .ui.cards > .card > .content {
  text-align: center;
  padding: 8px 0;
}

.DownloadPhotosModal .__ResizeField .input {
  width: 75px;
  margin-left: 4px;
}

.DownloadPhotosModal .__ResizeField .label {
  pointer-events: none;
}

.DownloadPhotosModal .__ResizeField input[type="number"] {
  padding: 8px 4px;
  text-align: right;
}
