.react-datepicker__input-container {
  width: 100%;
}

/*
This class is important because is what garantees that this specific Popup appears
on top of the Modal.
The z-index of Modals is overriden at the project's index.css to ensure they normally
appear on top of Popups.
*/
.CalendarPickerPopup {
  z-index: 3000 !important;
}

.CalendarPickerPopup .DayPicker-Months {
  min-height: 350px;
}
