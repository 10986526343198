.Calendar .Header {
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: space-around;
  padding: 12px;
}

.Calendar .Toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Calendar .Toolbar > * {
  margin: 5px;
}

.Calendar .Toolbar .backgroundLabel {
  border-radius: 15px;
  color: white;
  height: 30px;
  padding: 4px 10px;
}
